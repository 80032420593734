.chat-message-container {
    display: flex;
    margin-bottom: 2;
    align-items: center;
}

.chat-message {
    padding: 10px !important;
    display: flex;
    align-items: center;
    margin: 20px;
    max-width: 70%;
    min-height: 40px;
    border-radius: 40px !important;
}

.chat-main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 80px;
    padding-bottom: 60px;
    overflow: hidden;
}